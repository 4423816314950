<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col class="d-lg-none d-block py-1 bg-white">
        <b-link class="d-flex justify-content-center">
          <b-img
            src="./../../assets/images/avatars/image.png"
            rounded
            fluid
            alt="profile photo"
            class="bg-white logo_img"
          />
        </b-link>
      </b-col>
      <b-col lg="6" class="d-none d-lg-flex align-items-start mt-0">
        <div class="d-lg-flex justify-content-center sticky-top">
          <div class="mb-5">
            <!-- Brand logo  for large device -->
            <b-link class="brand-logo pb-5">
              <img src="./../../assets/images/logo/vep-logo.png" alt="" />
            </b-link>
          </div>
          <!-- Left Text for large device-->
          <div class="p-5">
            <b-img fluid :src="imgUrl" alt="Register V2" />
          </div>
        </div>
      </b-col>

      <!-- Register-->
      <b-col lg="6" class="auth-bg px-2 p-lg-5 pt-lg-0 pt-2 form">
        <b-col lg="12" md="10" sm="12" class="mx-auto">
          <div class="d-flex justify-content-between m-0">
            <b-card-title title-tag="h3" class="font-weight-bold m-0">
              Set Profile Picture
            </b-card-title>
            <p class="text-primary m-0">
              <u style="cursor: pointer" @click="skip">Skip this Step</u>
            </p>
          </div>
          <hr />
        </b-col>

        <!-- <div class="d-flex align-items-center mt-5">
          <b-col sm="8" md="6" lg="8" class="px-xl-2 mx-auto">
            <div class="flex__column">
         

              <b-avatar class="m-2" :src="url" size="100px" rounded />

              <b-button
                variant="primary"
                class="mt-5"
                @click="$refs.refInputEl.click()"
              >
                <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @change="onFileChange"
                />
                <span class="d-none d-sm-inline px-1"
                  >Upload Profile Picture</span
                >
                <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
              </b-button>
            </div>
          </b-col>
        </div> -->
        <b-col lg="10" md="10" sm="12" class="mx-auto">
          <form ref="form" @submit.stop.prevent="submitProfileImg">
            <!-- <div class="pl-5 pr-5 ml-3 mr-3"> -->
            <b-col lg="8" class="px-xl-2 mx-auto">
              <div class="flex__column">
                <b-avatar class="m-2" :src="url" size="100px" rounded />
                <b-button
                  variant="outline-primary"
                  class=""
                  @click="$refs.refInputEl.click()"
                >
                  <input
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    @change="onFileChange"
                  />
                  <span class="d-none d-sm-inline">Upload Profile Picture</span>
                  <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                </b-button>
              </div>
            </b-col>
          </form>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            class="mt-4"
            variant="primary"
            type="submit"
            @click.prevent="handleProfileImg"
          >
            Submit
          </b-button>
        </b-col>
      </b-col>
      <!-- /Set Profile Picture-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import router from "@/router"
import store from "@/store/index"
import VuexyLogo from "@core/layouts/components/Logo.vue"
import {
  BAlert,
  BAvatar,
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  VBTooltip,
} from "bootstrap-vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils"
import { avatarText } from "@core/utils/filter"
import { ref } from "@vue/composition-api"
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BAvatar,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {
        image: null,
      },
      type: "",
      pictureUrl: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      url: null,
      type: "",
      refInputEl: null,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg")
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    skip() {
      router.push({ name: "my-profile" })
    },
    onFileChange(e) {
      const file = e.target.files[0]

      this.form.image = file
      this.url = URL.createObjectURL(file)
    },
    handleProfileImg(bvModalEvent) {
      // Prevent modal from closing
      // bvModalEvent.preventDefault();
      // Trigger submit handler
      this.submitProfileImg()
    },

    submitProfileImg() {
      store
        .dispatch("candidateProfile/profileImage", this.form)
        .then((response) => {
          if (response.data.status === "success") {
            this.$router.push({ name: "myprofile" })
          }
        })
    },
  },
}
</script>

<style lang="scss">
.flex__column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@import "@core/scss/vue/pages/page-auth.scss";
@media only screen and (max-width: 950px) {
  .form {
    margin-top: -150px;
  }
  .formInput {
    margin-top: -150px;
  }
  .logo_img {
    width: 120px !important;
    margin-left: -80px;
  }
}
</style>
